<template>
  <div>
    <div class="footer-wrapper">
      <div class="footer-section f-section-1 text-center w-100">
        <p class="mt-4 small text-center mx-auto">
          © {{ new Date().getFullYear() }}. Gerocare Solutions LTD.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
